.Footer_black {
  background-color: var(--almost-black);
  color: var(--input-grey);
  width: 100%;
}

.Footer_footer {
  margin: 0 auto;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .Footer_footer {
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.Footer_footer > * {
  padding: 16px 0;
}

.Footer_copyright,
.Footer_nav {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.Footer_nav {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.Footer_nav a {
  color: var(--input-grey);
}

.Footer_nav a:not(:first-of-type) {
  margin-left: 30px;
}

@media (max-width: 1024px) {
  .Footer_copyright {
    -ms-flex-order: 3;
    order: 3;
    font-size: 12px;
    text-align: center;
  }
  .Footer_nav {
    -ms-flex-order: 2;
    order: 2;
    font-size: 12px;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .Footer_nav a:not(:first-of-type) {
    margin-left: 0;
  }
  .Footer_footer > * {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .Footer_footer > :not(:last-child) {
    border-top: 1px solid var(--charcoal-grey);
  }
  .Footer_social {
    -ms-flex-order: 1;
    order: 1;
  }
}
